<template>
  <div>
    <b-card title="短信配置">
      <el-divider content-position="left">禁启用配置</el-divider>
      <b-row>
        <b-col>
          <span>{{'短信剩余条数:'+tenantForm.smsRemainingCount}}</span>
        </b-col>
        <b-col>
          启用通知:
          <el-switch
            :disabled="!$checkButtonPermission('208003001')"
            v-model="tenantForm.smsEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="switchEnabled"
            >
          </el-switch>
        </b-col>
      </b-row>
      <el-divider content-position="left">短信通知配置</el-divider>
      <b-row>
        <b-col>
          启用配置:
          <el-switch
            :disabled="!$checkButtonPermission('208003002')"
            v-model="tenantForm.smsConfigEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="switchConfigEnabled"
            >
          </el-switch>
        </b-col>
        <b-col>
          若不启用，则使用平台默认参数
        </b-col>
      </b-row>
      <el-divider content-position="left">短信通知参数</el-divider>
      <b-row class="my-1">
        <b-col sm="4">
          <b-form-group
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-lg="7"
            description="单位:分钟"
            label="短信推送间隔"
            label-for="input-horizontal"
          >
            <b-form-input id="input-horizontal"type="number"  v-model="tenantForm.smsInterval" @change="(e)=>(tenantForm.smsInterval=numberCheck(e))"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label-cols-sm="6"
            label-cols-lg="4"
            content-cols-lg="7"
            description="单位:次"
            label="短信推送上限"
            label-for="input-horizontal"
          >
            <b-form-input id="input-horizontal" type="number" v-model="tenantForm.smsCeiling" @change="(e)=>(tenantForm.smsCeiling=numberCheck(e))"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" v-if="$checkButtonPermission('208003003')" @click="saveForm">保存</b-button>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      tenantForm:{
        id: "",
        smsCeiling: 0,
        smsConfigEnabled: false,
        smsEnabled: false,
        smsInterval: 0,
        smsRemainingCount: 0
      },

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTenantConfig()
  },
  methods: {
    getTenantConfig(){
      request.get('tob/tenant/tenantSysConfig/query').then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.tenantForm = {
            id: res.data.data.id,
            smsCeiling: res.data.data.smsCeiling,
            smsConfigEnabled: res.data.data.smsConfigEnabled,
            smsEnabled: res.data.data.smsEnabled,
            smsInterval: res.data.data.smsInterval,
            smsRemainingCount: res.data.data.smsRemainingCount
          }
        }
      })
    },
    switchEnabled(val){
      let parmas = {
        id: this.tenantForm.id,
        msType: 0,
        flag: val
      }
      request.post('tob/tenant/tenantSysConfig/enabledMs',parmas).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },
    switchConfigEnabled(val){
      let parmas = {
        id: this.tenantForm.id,
        msType: 0,
        flag: val
      }
      request.post('tob/tenant/tenantSysConfig/enabledMsConfig',parmas).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },

    saveForm(){
      let params = {
        id: this.tenantForm.id,
        msType: 0,
        interval: this.tenantForm.smsInterval,
        ceiling: this.tenantForm.smsCeiling
      }
      request.post('tob/tenant/tenantSysConfig/saveMsConfig',params).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
      })
    },

    numberCheck(value){
      let strArray = value.split(".");
      return parseInt(strArray[0]);
    },
  },
}
</script>

<style lang="scss">
</style>
